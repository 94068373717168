@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Lilita+One");
@import "~bootstrap/scss/bootstrap";

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  padding: 0;
  line-height: 1.25;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #202020;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #202020;
}

p.capitalize {
  text-transform: capitalize;
}

.cont {
  margin-top: 16vh;
  text-align: center;
}

.comp-width {
  width: 55%;
}

.alt-comp-width {
  width: 35%;
}

.logo {
  height: 3rem;
}

.search-container {
  width: 80%;
  z-index: 1;
  position: absolute;
  margin: auto;
  overflow: hidden;

  left: 0;
  right: 0;
  top: 5%;
}

.align-center {
  text-align: center;
}

.sprite {
  height: 100px;
  width: auto;
}

.heightdex-font {
  font-family: "Lilita One", cursive;
  color: #ffc10a;
  text-shadow: 2px 2px black;
}

.footer {
  text-align: center;
  font-size: 4vh;
  padding: 0 1rem;
}

#search {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgb(214, 214, 214);
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
}

#height-input {
  width: 5rem;
  margin: 0, 2rem;
}

.options-container {
  max-height: 70vh;
  background-color: white;
  overflow: auto;
  border-bottom: 1px solid rgb(214, 214, 214);
}

.number-badge {
  background-color: rgb(230, 230, 230);
}

.finger {
  cursor: pointer;
}

.height-input button {
  border-radius: 0;
}

#input-alert {
  width: 355px;
  text-align: center;
}

#unit-selector {
  color: black;
  background-color: rgb(206, 206, 206);
  border-color: rgb(206, 206, 206);
}

#spinner-container {
  height: 75vh;
}

#comp-height {
  font-weight: 700;
}

@media (max-width: 600px) {
  .options-container {
    max-height: 55vh;
  }

  #search {
    font-size: 1.2rem;
  }

  .cont {
    width: 100%;
  }

  #input-alert {
    width: 345px;
  }

  .cont {
    margin-top: 15vh;
  }

  .comp-width {
    width: 100%;
  }

  .alt-comp-width {
    width: 90%;
  }

  .small-description {
    font-size: 0.85rem;
  }
}
